import * as React from "react"
import { Container, Col, Row, Image } from "react-bootstrap"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { Icon } from "semantic-ui-react"

import MainIMG from "../images/iroda1.png";

export default function Index({ location }) {

  return (
    <Layout lang='hu' location={location}>
      <SEO lang='hu' title="Kezdőlap"/>
      <Container fluid className="h-100 d-flex flex-column">
        <Row className="flex-grow-1 primary-color-bg">
          <Col className="align-self-center index-content">
            <Container className="text-center">
              <h1 className="secondary-color-text index-name">Dr. Tihanyi Zoltán</h1>
              <h2 className="index-subtitle">Sebész szakorvos</h2>
              <p className="index-subtitle-text">Időpont előzetes egyeztetés alapján</p>
              <span className="text-left">
                <p className="index-text"><Icon name="phone" className='index-icon'/>+43676/3736655</p>
                <p className="index-text"><Icon name="mail" className='index-icon'/>ordination@chirurgie-tihanyi.com</p>
                <p className="index-text"><Icon name="home" className='index-icon'/>Schulgasse 42, 7400 Oberwart</p>
              </span>
            </Container>
          </Col>
          <Col className='index-img' style={{backgroundImage: `url(${MainIMG})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPositionY: -20}}>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}